import './App.css';
import weixin from './WeiXin.svg';
import weibo from './WeiBo.svg';
import facebook from './FaceBook.svg';
import twitter from './Twitter.svg';
import photo1 from './photo1.png';
import photo2 from './photo2.png';
import photo3 from './photo3.png';
import photo4 from './photo4.png';
import photo5 from './photo5.png';
import photo6 from './photo6.png';
import photo7 from './photo7.png';
import photo8 from './photo8.png';
import photo9 from './photo9.png';

function Logo() {
  return (
    <div className='Logo'>Neversee</div>
  );
}

function Tag() {
  return (
    <div className='Tag'>
      <ul>
        <li className='selected'>人 像</li>
        <li>景 观</li>
        <li>城市景观</li>
      </ul>
    </div>
  );
}

function AboutMe() {
  return (
    <div className='AboutMe'>
      <p>关于我</p>
      <ul className='Icons'>
        <li className='WeiXin'>
          <img src={weixin} alt="" />
        </li>
        <li className='WeiBo'>
          <img src={weibo} alt="" />
        </li>
        <li className='FaceBook'>
          <img src={facebook} alt="" />
        </li>
        <li className='Twitter'>
          <img src={twitter} alt="" />
        </li>
      </ul>
    </div>
  );
}


function SideBar() {
  return (
    <div className='SideBar'>
      <Logo />
      <Tag />
      <AboutMe />
    </div>
  );
}

function Album() {
  return (
    <div className='Album'>
      <img src={photo1} alt=''></img>
      <img src={photo2} alt=''></img>
      <img src={photo3} alt=''></img>
      <img src={photo4} alt=''></img>
      <img src={photo5} alt=''></img>
      <img src={photo6} alt=''></img>
      <img src={photo7} alt=''></img>
      <img src={photo8} alt=''></img>
      <img src={photo9} alt=''></img>
      <div className='Footer'>
        <p>Neversee photograph.1998-2024</p>
        <p>个人摄影作品集</p>
        <p><br/></p>
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer" >黑ICP备2024020659号-2</a>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <div className='Container'>
          <SideBar />
          <Album />
      </div>
    </div>
  );
}

export default App;
